.invoices-container {
    .loading {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba($color: black, $alpha: 60%);
        color: white;
    }

    .invoices-content {
        margin: 0 1rem;

        .form-label {
            .label-text {
                margin-bottom: 0.5rem;
                font-size: 18px;
                font-weight: 600;
            }

            .form-select {
                align-self: start;
                padding: 0.5rem 1rem;
                font-size: 14px;
                margin-bottom: 1rem;
            }
        }

        .invoice-panel-container {
            margin-bottom: 1rem;

            .invoice-panel-header {
                background-color: #d68c3c;
                color: white;
                border-radius: 4px 4px 0 0;
                padding: 0.3rem;

                .header {
                    margin: 0.5rem 1rem;
                }
            }

            .invoice-panel-content {
                padding: 0.5rem;
                background-color: whitesmoke;
                border-top: 0;
                border-radius: 0 0 4px;

                .description-container {
                    padding: 0.5rem;
                    border: 1px solid grey;
                }

                .invoice-table {
                    margin-top: 0.5rem;
                    padding: 1rem;
                    background-color: white;

                    .table-column {
                        padding: 0.5rem;
                        border-bottom: 1px solid black;
                    }

                    .table-row {
                        &:nth-child(even) {
                            background-color: whitesmoke;
                        }
                    }
                }
            }
        }
    }
}
