@use 'styles/buttons';
@use 'styles/flex';

.page-container {
    height: 100%;

    .video-background {
        position: fixed;
        object-fit: cover;
        object-position: center center;
        height: 100%;
        width: 100%;
        background-color: black;
    }

    .login-container {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;

        .content-container {
            padding: 1rem 3rem;
            background-color: rgba(0, 0, 0, 0.7);
            border-radius: 0.25rem;
            box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.2);

            .login-header {
                font-size: 3rem;
                color: white;
            }

            .btn-primary {
                display: flex;
                margin: 0 auto;
            }

            @media (min-width: 640px) {
                padding: 2rem 5rem;

                .login-header {
                    font-size: 3rem;
                    padding: 1rem 2rem;
                }

                .btn-primary {
                    font-size: 1.5rem;
                    padding: 1rem 2rem;
                }
            }
        }
    }
}
