.submitted-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem; 
    background-color: white;
    color: black;
    text-align: center;
    box-shadow: 1px 1px 15px -2px black;

    .modal-content {
        display: flex;
        margin: 0 2rem;
    }
}

.dashboard-container {
    .loading {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba($color: black, $alpha: 60%);
        color: white;
    }

    .dashboard-content {
        margin: 1rem;

        .grid {
            display: grid;
            gap: 1rem;

            &.col-3 {
                grid-template-columns: repeat(3, minmax(5rem, 1fr));

                @media (max-width: 640px) {
                    grid-template-columns: minmax(5rem, 1fr);
                }
            }

            &.col-2 {
                grid-template-columns: repeat(2, minmax(5rem, 1fr));

                @media (max-width: 640px) {
                    grid-template-columns: minmax(5rem, 1fr);
                }
            }

            .grid-item {
                position: relative;
                box-shadow: 4px 4px 6px rgba(black, 0.25);
                border-radius: 4px;
                border: 1px solid black;
            }
        }
    }
}
