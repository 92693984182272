@use 'styles/buttons';

.navbar {
    .navbar-items {
        margin: 0;
        padding: 0;
        background-color: #20232a;

        .nav-brand {
            margin: 0 1rem;
            list-style: none;

            .logo {
                height: 3rem;
                width: 3rem;
            }
        }

        .nav-item {
            list-style: none;

            .btn-nav-link {
                @include buttons.button-link;
            }
        }
    }
}
