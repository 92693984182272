@import-normalize;

html {
  height: 100%;
  
  body {
    height: 100%;
    margin: 0;
  
    div#root {
      height: 100%
    }
  }
}