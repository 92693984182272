.keg-panel {
    position: relative;

    .loading {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba($color: black, $alpha: 60%);
        color: white;
    }

    .panel-header {
        background-color: #d68c3c;
        color: white;
        border-radius: 4px 4px 0 0;

        .header {
            margin: 0.25rem 1rem;
        }
    }

    .panel-content {
        padding: 0.5rem;
        background-color: whitesmoke;
        border-top: 0;
        border-radius: 0 0 4px;

        .description-container {
            padding: 0.5rem;
            border: 1px solid grey;

            .header {
                font-size: 24px;
                margin: 0;
            }

            .ingress {
                font-size: 16px;
            }

            .link {
                display: flex;
                font-size: 14px;
                font-weight: 600;
                color: #ed8e26;

                .external-link {
                    fill: #ed8e26;
                    height: 1rem;
                    width: 1rem;
                    margin-left: 0.3rem;
                }
            }
        }

        .status-container {
            display: flex;
            flex-direction: row;
            
            .header {
                margin: 0.5rem 0 0;
            }
        }

        .button-container {
            display: grid;
            grid-template-columns: repeat(2, minmax(2rem, 1fr));
            gap: 0.5rem;
            border: none;

            .btn-primary {
                padding: 1rem;
                color: white;
                background-color: #d68c3c;
                border: 1px solid black;

                &:hover {
                    background-color: #ed8e26;
                }
            }

            @media (max-width: 640px) {
                grid-template-columns: minmax(2rem, 1fr);
            }
        }

        .history-container {
            .history-header {
                margin: 1rem auto 0;
            }

            .history-list {
                padding: 0;
                list-style: none;

                .history-item {
                    padding: 0.5rem;
                    border: 1px solid black;
                    align-self: flex-start;
                    justify-content: space-between;

                    & + .history-item {
                        margin-top: 0.5rem;
                    }

                    .history-item-header {
                        margin: 0 0 0.5rem 0.5rem;
                    }

                    .history-item-content {
                        margin: 0 0 0 0.5rem;
                    }

                    .history-item-delete-button {
                        margin-left: 2rem;
                    }
                }
            }
        }
    }
}
