.is-flex {
    display: flex;

    &.direction {
        &-row {
            flex-direction: row;
        }

        &-column {
            flex-direction: column;
        }
    }

    &.justify {
        &-evenly {
            justify-content: space-evenly;
        }

        &-start {
            justify-content: flex-start;
        }

        &-center {
            justify-content: center;
        }
    }

    &.align-items {
        &-center {
            align-items: center;
        }
    }
}
