@mixin button-link {
    display: flex;
    height: 100%;
    padding: 0 1rem;
    color: #ed8e26;
    background: none;
    border: none;
    align-items: center;

    &.active {
        background: #ed8e26;
        color: white;
    }

    &:hover {
        cursor: pointer;
        color: white;
    }
}

.btn {
    text-decoration: none;

    &.btn-primary {
        background: #ed8e26;
        color: white;
        padding: 0.5rem 1rem;
        border: 1px solid black;

        &:hover {
            cursor: pointer;
            color: white;
        }
    }
}
